.empty_state {
  position: relative;
  top: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.empty_state i {
  margin: auto;
  margin-bottom: 0px;
  font-size: 90px;
  color: #ccc;
}
.empty_state h3 {
  margin: 8px 0px;
  text-align: center;
  font-weight: normal;
}
.empty_state p {
  font-size: 14px;
  margin: 0px;
  color: #999;
  text-align: center;
}
.empty_state button {
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 8px 8px;
  margin: 20px auto auto auto;
  width: 50%;
  max-width: 200px;
  background: #348AC7;
  color: white;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: 12px;
}