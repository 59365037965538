.recomended_div {
  /* background-color: #EDF2FA; */
  /* padding-top: 100px; */
  padding: 50px 0;
}
.recomended_div :global(.main) {
  margin: 0px 321px;
}

.tabsDiv :global(.nav-item) button {
  margin-bottom: 0 !important;
  border-radius: 30px !important;
}

.tabsDiv :global(.nav-tabs) {
  border-radius: 40px;
}

.tabsDiv :global(.nav-item) button:hover {
  background-color: rgb(234, 238, 251);
}

.tabsDiv :global(.nav) {
  border-radius: 40px;
}

.tabs {
  color: #39414a;
  font-size: 16px;
  font-weight: 500;
}

.tabs:hover {
  color: #5c13dae4;
}
.tabs:active {
  color: #5c13dae4;
}
.active_tab {
  color: #5c13dae4;
  font-size: 16px;
  font-weight: 00;
  border-bottom: 2px solid;
}

/* .link{
    font-size: 18px !;
} */
@media screen and (max-width: 1600px) {
  .recomended_div :global(.main) {
    margin: 0px 152px;
  }
}
.re_header {
  font-size: 24px;
}
.re_paragraph_div {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}
.para {
  order: 1;
  display: flex !important;
  margin-left: auto;
}
.arrows {
  order: 2; /* places this element second */
  margin-left: auto;
}

.slider_arrow {
  position: absolute;
  height: 75%;
  width: 100%;
  /* top: 148%; */
}
.arrow_btn_div {
  /* top: 6%;s */
  z-index: 1;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.arrow_btn_div_right {
  /* top: 6%; */
  z-index: 1;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.arrow_btn_btn {
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 0px !important;
  height: 40px;
}
/* .re_link{
    color:#5932EA;
    text-decoration: underline;
    font-size: 18px;
  } */

@media screen and (max-width: 1024px) {
  .slider_div {
    width: 300;
  }
  .recomended_div :global(.main) {
    margin: 0px 0px !important;
  }
  .para {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1440px) {
  .recomended_div :global(.main) {
    margin: 0px 60px;
  }
}
@media screen and (max-width: 576px) {
  .re_header {
    font-size: 18px;
  }

  .para {
    font-size: 14px;
  }
  .slider_arrow {
    height: 73%;
    /* top: 148%; */
  }
  .arrow_btn_div {
    width: 48px;
  }
  .arrow_btn_div_right {
    width: 48px;
  }
  .recomended_div {
    padding: 16px 0 16px 0;
  }
  .recomended_div :global(.nav) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    font-size: 14px !important;
    text-wrap: nowrap;
  }
  .tab {
    font-size: 14px;
  }
  .active_tab {
    font-size: 14px;
  }
  .recomended_div :global(.row) {
    margin: 0 !important;
  }
  .link {
    font-size: 14px !important ;
  }
}

@media screen and (max-width: 768px) {
  .re_header {
    font-size: 18px;
  }
  .recomended_div {
    padding: 32px 0;
  }
  .recomended_div :global(.nav) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    font-size: 14px !important;
    text-wrap: nowrap;
    justify-content: flex-start !important;
  }
  .link {
    font-size: 14px !important;
  }
}
.link {
  font-size: 18px;
}

.link:hover {
  border-bottom: 2px solid black !important;
}
