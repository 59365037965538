.specDetail {
    gap: 2rem;
}
.badge:hover{
    border-color: #a93bff !important;
} 
.ActiveBadge{
    border-color: #a93bff !important;
}
.specImage {
    width: 195px;
    height: 260px;
    padding: 0 !important;
}

.specImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.basicDetails {
    font-size: 16px;
    line-height: 130%;
}

.specsTable tr {
    border-bottom: 1px solid var(--table-border) !important;
}


.specsTable .tablehead {
    background-color: #edf2fa7e;
    font-size: 18px;
    font-weight: normal;
    color: #39414a;
    line-height: 130%;
}

.specsTable .tablehead tr {
    background-color: inherit;
}

.specBasic h4 {
    font-size: 30px;
}

.specImage2 {
    height: auto;
    width: 195px;
}

.specDetail h3 {
    font-size: 30px;
}

.tdhead {
    font-weight: 500;
    font-size: 16px;
    line-height: 200%;
}

.specBasicSm div {
    gap: 0.5rem !important;
}


.specDetailAccordion :global(.accordion-header){
    background-color: #EDF2FA !important;
}
.specDetailAccordion :global(.accordion-button){
    background-color: #EDF2FA !important;
}

.colorDiv{
    border-top: 2px solid #D8E4F8;
    width: 100%;
}


.colorThumbnail{
    width: 64px;
    height: 64px;
    border: 1px solid #39414a;
    border-radius: 5px;
    overflow: hidden;
}
.activeColor{
    border: 2px solid  #a93bff;
}

.verticalColorsHeading{
    border-bottom: 2px solid #D8E4F8;

}

.verticalColorsDesign{
    height: 420px;
    overflow: auto;
}


.recomendedProductsTitle{
    font-size: 24px;
    border-bottom: 1px solid #D8E4F8;
    padding: 0 5px 8px 5px;
}

.wantToBuyButton{
    cursor: pointer;
}

.wantToBuyDiv{
    display: flex;
    gap: 0.25rem !important;
    align-items: center !important;
    flex-wrap: nowrap;
}
.wantToBuyDiv > .wantToBuyButtonIcon{
    width: 16px; height: 16px; display: flex;
}
.wantToBuyDiv > .wantToBuyButtonText{
    font-weight: 500; font-size: 14px;
}

.wantToBuyButton:hover{
    background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
}

.wantToBuyButton:hover .wantToBuyButtonText{
    color: white;
}
.wantToBuyButton:hover .wantToBuyButtonIcon{
    filter: invert(100%);
}



.tabOptionsContainer{
    /* background-color: #f8f9fa!important; */
    color: #55555577;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700!important;
}
.tabOptions{
    cursor: pointer;
    text-align: center;
    padding: 0.5rem 1rem;
    border: 1px solid #DEE2E6;

}

.startBorderImportant{
    border-left: 1px solid #DEE2E6 !important;
}
.endBorderImportant{
    border-right: 1px solid #DEE2E6 !important;
}

.activeTabOption{
    color: #555555;
    background-color: #EDF2FA !important;
    /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
    border-bottom: 0 !important;
}

.specs h1 {
    font-size: 24px ;
}


@media screen and (max-width: 1300px) {
    .specDetail {
        gap: 0.5rem;
    }
}

@media screen and (max-width: 1050px) {
    .specDetail {
        gap: 1rem;
    }
    .specImage {
        width: auto;
        height: 100%;
        padding: 0 !important;
    }
    
    .specImage img {
        width: 160px;
        height: 100%;
        object-fit: contain;
    }
    .specBasic h4 {
        font-size: 26px;
    }    
    .specImage2 {
        height: auto;
        width: 160px;
    }
}

@media screen and (max-width: 768px) {
    .recomendedProductsTitle{
        font-size: 18px;}
    .specImage img {
        width: 135px;
    }
    .specs h1 {
        font-size: 18px !important;
    }
    .keyTitle{
        font-size: 18px;
    }
}

@media screen and (max-width: 575px) {
    .recomendedProductsTitle{
        font-size: 18px;}
    .specBasic h4 {
        font-size: 24px;
    } 
    .specDetail h3 {
        font-size: 24px;
    }
    .basicDetails li {
        font-size: 13px;
    }
    .specs h1 {
        font-size: 18px;
    }
    .specsTable .tablehead {
        font-size: 14px;
    }
    .specImage img {
        width: 120px;
        height: 100%;
        object-fit: contain;
    }
}