.filter_name {
    background-color: #f8f9fa;
    /* color: #721c24; */
}
.filter_value {
    background-color: #0e0249;
    color: #ffffff;
}
.fs-12 {
    font-size: 14px;
}
/*Apply overlay with Text Clear on .filter-badge on hover */
.filter_badge {
    position: relative;
    width: -moz-fit-content; /* Add this line to support Firefox 3+ and Firefox for Android 4+ */
    width: fit-content;
}
.pill_shadow {
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.15) !important;
}

.fit {
    width:fit-content;
}

.filter_badge::after {
    content: "X";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* semi-transparent overlay */
    color: white; /* text color */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* allows clicking through the overlay */
    /* border-radius: 0.25rem; */
    border-radius: 0.45rem;
}

.filter_badge:hover::after {
    opacity: 1;
}

.hr-transform {
    /*90 degress*/
    transform: rotate(90deg);
}
