/* Write page CSS here*/
.message_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success_container {
  background: white;
  height: 480px;
  width: 90%;
  box-shadow: 5px 5px 10px grey;
  text-align: center;
}

.confirm_green_box {
  width: 100%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.confirm_green_box_image {
  object-fit: contain;
}

.monserrat_font {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
}

/* --------------- site wide START ----------------- */

.main {
  width: 80vw;
  margin: 0 10vw;
  height: 50vh;
  overflow: hidden;
}

/* 
   * Setting the site variables, example of how to use
   * color:var(--text-1);
   *
   */

.verticle_align {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_style {
  padding: 0;
  margin: 0;
}

/* ------------------ site wide END ----------------- */

/* ----- RESPONSIVE OPTIONS MUST STAY AT BOTTOM ----- */

/* SM size and above unless over ridden in bigger sizes */
@media (max-width: 576px) {
  /* sm size */
  .msgTitle {
    font-size: 24px;
    text-align: center;
  }
}

/* MD size and above unless over ridden in bigger sizes */
@media (min-width: 768px) {
}

/* LG size and above unless over ridden in bigger sizes */
@media (min-width: 992px) {
}

/* XL size and above */
@media (min-width: 1200px) {
}
