/* Separater styles */
.separater {
  color: #d8e4f8;
  border-top: 3px solid;
}

.gridDiv {
  display: grid;
  grid-template-columns: 3fr 4fr 4fr;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  row-gap: 30px;
  column-gap: 30px;
  max-height: 500px;
}

.condition_container {
  max-width: 4.4rem;
  line-height: 1;

  border-radius: 0.5rem 0px 0.5rem 0px;
  -webkit-border-radius: 0.5rem 0px 0.5rem 0px;
  -moz-border-radius: 0.5rem 0px 0.5rem 0px;

  /* From https://css.glass */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.top_seller_div {
  /* grid-area: 1 /1 /3/1; */
  z-index: 0;
}

.recomendedCard {
  /* max-width: 200px; */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.1);
}

.featured {
  /* background: rgb(255 160 172); */
  right: 0px;
  border-bottom-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background: rgb(235 15 15 / 43%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.top_seller_div,
.best_sellers,
.deals,
.fourth_div,
.fiveth_div {
  background-color: #f5f6fa;
  border-radius: 12px;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.15);
  background-color: #f5f6fa;
  padding: 10px;
}

.fiveth_div {
  background: rgb(248, 246, 255);
}
.deals {
  /* overflow: hidden; */
  background: rgb(248, 246, 255);
}


/* SellerSlider styles */
.sellerSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  /* width: 100px;
    height: 100px; */
}

/* SellerImage styles */
.sellerSlider img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  height: 96px;
  width: 96px;
}

/* ShopName styles */
.shopName {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 10px;
}

/* Header styles */
.header {
  max-width: 100%;
  margin: auto;
  font-size: 24px;
}
.topSellers {
  background-color: white;
  padding: 32px 0;
  /* max-height: 500px; */
  /* height: 500px !important; */
}
/* [.top_seller_div {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.15);
  padding: 20px;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #f5f6fa;
}] */

.top_seller_div :global(.swiper) {
  max-height: 460px !important;
}

.top_Sellerheight :global(.swiper) {
  height: 200px !important;
}

.seller_logo {
  width: 64px;
  height: 64px;
}
.discount {
  background: rgb(255 160 172);
  right: 0px;
  border-bottom-left-radius: 7px;
  background: rgb(235 15 15 / 43%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.recomCard {
  mix-blend-mode: multiply;
  object-fit: contain;
  max-width: 100px;
  height: 4.5rem;
}

.seller_logo img {
  object-fit: contain !important;
}

/* make a top seller card */
.top_seller_card {
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  background-color: #fff; /* we may remove the color or adjust as per need */
  box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.1);
}

.skeleton {
  background: linear-gradient(90deg, hsl(210, 15%, 88%), hsl(210, 15%, 95%), hsl(210, 15%, 88%));
  background-size: 200%;
  animation: loader 1s infinite reverse;
}



/* Media query for screens with a max-width of 575px */

@media screen and (max-width: 1440px) {
  .topSellers :global(.main) {
    margin: 0px 60px !important ;
  }

}

@media screen and (max-width: 1024px) {
  .topSellers :global(.main) {
    margin: 0px 0px !important;
  }
  .salesDiv {
    justify-content: center !important;
  }
  .deals {
    justify-content: end !important;
  }
  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px !important;
  }
}
@media screen and (max-width: 924px) {
  .deals .recomCard {
    height: 200px !important;
  }
  .deals p {
    align-items: center !important;
  }
  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px !important;
  }
}

@media screen and (max-width: 1600px) {
  .topSellers main {
    margin: 0px 152px !important ;
  }
}

@media screen and (max-width: 768px) {
  .topSellers :global(.main) {
    margin: 0px 0px !important ;
  }
  .header {
    font-size: 18px !important;
  }
  .topSellers p {
    font-size: 14px !important;
    text-wrap: wrap !important;
  }

 
  .gridDiv {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto; /* Automatically adjust rows */
    row-gap: 20px; /* Add spacing between rows */
  }

  .best_sellers {
    grid-area: auto; /* Default flow */
  }

 

  .salesDiv {
    justify-content: center !important;
  }
}
@media screen and (max-width: 575px) {
  .topSellers :global(.main) {
    margin: 0 !important;
  }

  

  .top_seller_div :global(.swiper) {
    max-height: 100px !important;
  }

  .topSellers {
    padding: 16px 0 !important;
  }
  .header {
    font-size: 18px !important;
  }

  .sellerSlider {
    max-width: 250px;
    max-height: 200px;
  }

  .topSellers :global(p) {
    font-size: 12px !important;
    text-wrap: nowrap !important;
  }

  .gridDiv {
    grid-template-columns: 1fr; /* Single-column layout */
    grid-template-rows: auto; /* Automatically adjust rows */
    row-gap: 20px; /* Add spacing between rows */
  }

  .best_sellers {
    grid-area: auto; /* Default flow */
  }

  .fiveth_div {
    max-height: 300px;
  }

  .deals {
    max-height: 200px !important;
  }
}
