.seller_div :global(.main) {
  margin: 0px 155px;
}
.sidefilter_div {
  display: block;
}
.sellerSlider a:focus-visible {
  outline: none !important;
}
@media screen and (max-width: 1600px) {
  .seller_div :global(.main) {
    margin: 0px 50px;
  }
  .sidefilter_div {
    display: block !important;
  }
  .contact_div {
    display: block;
  }
}
@media screen and (max-width: 1300px) {
  .seller_div :global(.main) {
    margin: 0px 10px;
  }
  .sidefilter_div {
    display: block;
  }
  .contact_div {
    display: block !important;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 9fr !important;
  }
}
@media screen and (max-width: 1024px) {
  .seller_div :global(.main) {
    margin: 0px 10px;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 9fr !important;
  }
  .sidefilter_div {
    display: none !important;
    padding-left: 0px !important;
  }
  .contact_div {
    display: none !important;
  }
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.wrapper_default {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
@media screen and (max-width: 768px) {
  .wrapper_default {
    display: grid;
    grid-template-columns: auto ;
  }
  .sidefilter_div {
    display: block !important;
  }
  .contact_div {
    display: none !important;
  }
}
@media screen and (max-width: 1280px) and (min-width: 768px) {
  .wrapper_default {
    display: grid;
    grid-template-columns: auto ;
  }
 } 


@media screen and (max-width: 1280px) {
  .sidefilter_div{
    display: none !important;
  }
  .contact_div{
    display: none !important;
  }
  
} 