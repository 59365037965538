.fav_icon {
    margin-top: 6px;
    margin-right: 6px;
    padding: 1px;
    border: 1px solid #d8e4f8;
    background-color: white !important;
    border-radius: 50%;
    padding-bottom: 6px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Add border shadow */
}

