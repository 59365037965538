.product_title {
  overflow: hidden;
  display: -webkit-box;
  line-height: 1.3;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.product_title:hover {
  color: #d957f9;
}
.soldOut > p {
  color: #ff4545 !important;
}

.product_price_container {
  width: fit-content;
}

.product_price_container > span {
  font-size: 13px;
}

.product_price_container > p {
  font-size: 14px;
}

.product_price_container > span.product_discount {
  font-size: 13px;
}

.condition_container {
  width: 5rem;
  line-height: 1;

  border-radius: 0.5rem 0px 0.5rem 0px;
  -webkit-border-radius: 0.5rem 0px 0.5rem 0px;
  -moz-border-radius: 0.5rem 0px 0.5rem 0px;

  /* From https://css.glass */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.cardImg {
  object-fit: contain;
  height: 100%;
}
.cardImgContainer {
  height: 160px;
  max-height: 192px;
  overflow: hidden;
}

.store {
  color: #5932ea;
  font-weight: 500;
  font-size: 12px;
}

.location {
  color: #5932ea;
  font-weight: 500;
  font-size: 12px;
}
.product_price_container span {
  color: black !important;
}

.store:hover {
  color: #d957f9 !important;
}
.discount {
  background: rgb(255 160 172);
  right: 0px;
  border-bottom-left-radius: 7px;
  background: rgb(235 15 15 / 43%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.featured {
  /* background: rgb(255 160 172); */

  z-index: 0;
  background: rgba(169, 15, 235, 0.621) !important;

  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.cards {
  border-radius: 0.8rem 0px 0.8rem 0px !important;
}
@media screen and (max-width: 992px) {
  .cardImgContainer {
    height: 128px;
    max-height: 160px;
    overflow: hidden;
  }
}

@media screen and (min-width: 306px) and (max-width: 416px) {
  .card {
    height: 100%;
  }
  .cardImgContainer {
    /*height: 96px;*/
    max-height: 128px;
    overflow: hidden;
  }
  .product_title {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .qtyLeft {
    font-size: 10px;
  }
  .cut_price {
    font-size: 10px;
  }
}
