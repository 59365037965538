.h1{
    font-size: 24px;
}
.listin_div{
    border-bottom:1px solid #D8E4F8 ;
}
.image_div{
    border: 1px solid #D8E4F8;
}
.filter_btn{
    background: #EDF2FA !important; 
    color:#100146
  }
    .gridItems {
        padding: 15px 10px;
        height: 145px;
        max-width: 200px;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
        transform-origin:106px !important
        
    }

    .gridItems img {
        height: 64px;
        width: 64px;
    }

    @media  screen and (max-width: 1600px) {
        .gridItems img{
            height:64px;
            width: 64px;
        
    }
   

}
    @media screen and (max-width: 768px) {
        .h1{
            font-size: 18px;
        }
        /* .gridItems {
            height: 145px;
            max-width: 129px;
            
        } */
        .gridItems img{
            height: 64px;
            width: 64px;
        }
    }


    @media screen and (max-width: 576px) {
        .h1{
            font-size: 18px;
        }
        /* .gridItems {
            height: 125px;
            max-width: 129px;
            
        } */
        .gridItems img{
            height: 48px;
            width: 48px;
        }
    }