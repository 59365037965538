.btn {
  border-radius: 7px 0px 0px 7px !important;
}
.btn_minus {
  border-radius: 0px 7px 7px 0px !important;
}

.itemCard {
  border-radius: 13px;
}

.itemImg {
  border-radius: 13px;
}
.Voucher_btn{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.voucher_input{
   border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

}
.badge{
  display: inline;
}
/* .itemTitle{
    width: min-content !important;
    text-wrap: nowrap !important;
} */

.voucherRow{
  width: max-content;
flex-wrap:nowrap;
}

@media screen and (max-width: 500px) {
  .itemDetails > span:first-child {
    font-size: 13px !important;
  }
  .itemImg {
    width: 60px !important;
    height: 60px !important;
  }

  .itemTitle {
    font-size: 13px;
    width: min-content !important;
    text-wrap: nowrap !important;
  }
  .itemPrice {
    font-size: 14px !important;
  }
  .Voucher_btn{
    font-size: 10px !important;
    padding-left:7px !important; ;
    padding-right: 10px !important;
  }
  .voucher_input{
    font-size: 10px !important;
    padding-left:10px !important; ;
    padding-right: 10px !important;
     margin-left: 5px !important;
  }
    .error{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 500px) {
  .badge{
    display: table;
  }
}
.tabs {
  background-color: rgb(234, 228, 255);
  /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 15px; */
  color: black;
  font-weight: 600;
}

.accordion_header :local(.accordion-button) {
  border-radius: 0px !important;
}

