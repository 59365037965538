.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #100146;
}

.price {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #39414a;
}

.blur {
  filter: blur(2px);
  pointer-events: none;
}

.originalPrice {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #39414a;
}

.list {
  font-weight: 600;
}

.availability {
  color: #a93bff;
}
/* .wishlist{
    background-color: var(--bg-secondary);

} */
.discount {
  background-color: rgba(47, 190, 57, 0.1);
  border: 0.5px solid rgba(47, 190, 57, 0.3);
  border-radius: 4px;
  font-weight: 700 !important;
  padding: 3px 5px;
  font-size: 20px;
  line-height: 130%;
}

.separator {
  color: #d8e4f8;
  border-top: 2px solid;
  width: 100%;
}

.quantity {
  font-size: 12px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
}

.grid p {
  font-size: 12px;
  text-align: center;
}

.colors {
  width: 45px;
  height: 36px;
  background-color: rgba(169, 208, 191, 1);
  cursor: pointer;
}

.number {
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #100146;
  font-size: 20px !important;
}
.whatsappIcon:hover {
  color: #a93bff;
}
.emailIcon:hover {
  color: #a93bff;
}
.items {
  padding: 2px;
}

.items:hover {
  border: 1px solid var(--active-color);
}

.actionBtns {
  background-color: var(--bg-secondary);
}

.actionBtns span {
  cursor: pointer;
}

.actionBtnWa {
  background-color: #2fbe39;
  color: #fff;
}

.actionBtnWa span {
  cursor: pointer;
}

.actionBtnWa h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
}

.emailheader {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.checkbox input {
  border-radius: 50% !important;
}

.emailForm {
  background-color: var(--bg-secondary);
  transition: all 0.5s linear;
}

.waBtn {
  background: #fff !important;
  color: #2fbe39 !important;
}

.header {
  background: var(--bg-secondary);
  border: 1px solid #d8e4f8;
  padding-bottom: 2px;
}

.infoSection {
  /* border: 1px solid var(--bg-secondary); */
  border-radius: 0px 6px 6px 6px;
  max-width: 100%;
  margin: auto;
  padding: 15px 0px;
  align-items: center;
}

.logo {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  overflow: hidden;
  padding: 3px;
  object-fit: contain;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.infoSection h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #39414a;
}

.shopLogo {
  border-radius: 50%;
}

.quadrilateral {
  border-bottom: 29px solid #edf2fa;
  border-right: 25px solid transparent;
  height: 0;
  width: 97px;
}
.quadrilateral_content {
  padding: 6px;
  height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
}
.reserve_btn:focus-visible {
  box-shadow: none;
}

.bottomButton {
  bottom: 0;
  box-shadow: 0 -2px 3px #0000001a;
  background: #100146;
  left: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}
 .installments_link{
  color: #4f772d;
width: max-content !important;
  /* justify-content: center; */
  display: flex;

} 

 .installments_link:hover{
  color: #a93bff !important;
  text-decoration: underline;
 }

@media screen and (max-width: 1200px) {
  .productDetails :global(.main) {
    margin: 0px 0px !important;
  }
}
.bottomButton {
  bottom: 0;
  box-shadow: 0 -2px 3px #0000001a;
  background: #100146;
  left: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
}

.skeleton {
  background: linear-gradient(90deg, hsl(210, 15%, 88%), hsl(210, 15%, 95%), hsl(210, 15%, 88%));
  background-size: 200%;
  animation: loader 1s infinite reverse;
}
.specsTable tr {
  border-bottom: 1px solid var(--table-border) !important;
}

.specsTable .tablehead {
  background-color: #edf2fa7e;
  font-size: 18px;
  font-weight: normal;
  color: #39414a;
  line-height: 130%;
}

.specsTable .tablehead tr {
  background-color: inherit;
}

@media screen and (max-width: 1200px) {
  .productDetails :global(.main) {
    margin: 0px 0px !important;
  }
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 24px;
  }

  .price {
    font-size: 18px;
  }

  .originalPrice {
    font-size: 16px;
  }

  .discount {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 18px;
  }
  .list {
    font-size: 14px;
  }

  .location {
    font-size: 14px !important;
  }
  .re_heading {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  .title {
    font-size: 18px;
  }

  .price {
    font-size: 18px;
  }
  .originalPrice {
    font-size: 14px;
  }

  .discount {
    font-size: 14px;
  }

  .infoSection h5 {
    font-size: 20px;
  }
  .list {
    font-size: 14px;
  }
  .location {
    font-size: 14px !important;
  }
  .re_heading {
    font-size: 18px;
  }
}
