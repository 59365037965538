.logo {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    padding: 3px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.map iframe {
    max-width: 100%;
}

.actionBtns {
    background-color: var(--bg-secondary);
}

.actionBtns span {
    cursor: pointer;
}

.actionBtnWa {
    background-color: #2FBE39;
    color: #fff;
}

.actionBtnWa span {
    cursor: pointer;
}

.separater {
    color: #D8E4F8;
    border-top: 3px solid;
    width: 100%;
}

.mailTxt {
    color: #100146 !important;
}

.checkbox input {
    border-radius: 50% !important;
}

.emailForm {
    background-color: var(--bg-secondary);
    transition: all 0.5s linear;
}

.waBtn {
    background: #fff !important;
    color: #2FBE39 !important;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 6px;
}

.actionBtnsm {
    background-color: var(--bg-secondary);
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 5px;
    gap: 5px;
    cursor: pointer;
}

.actionBtnsm:hover {
    background-color: var(--secondary-color);
    color: #fff;
}
.form_div{
    background-color:  #EDF2FA;
    border: 1px solid #D957F9;
}
.shopname{
    font-size: 24px;
}

@media screen and (max-width: 768px) {
    .shopname{
         font-size: 18px;
         margin-right:20px ;
     }
     .contact_btn :global(.btn){
        font-size: 14px !important;
     }
}
@media screen and (max-width: 576px) {
    .shopname{
         font-size: 18px;
         margin-right:20px ;
     }
     .contact_btn :global(.btn){
        font-size: 14px !important;
     }
    
}




