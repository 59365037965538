.logo {
    width: 99px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    padding: 3px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.map iframe {
    max-width: 100%;
    border: 0;
    border-radius: 4px;
}

.actionBtns {
    background-color: var(--bg-secondary);
}

.actionBtns span {
    cursor: pointer;
}

.actionBtnWa {
    background-color: #2FBE39;
    color: #fff;
}

.actionBtnWa span {
    cursor: pointer;
}

.separater {
    color: #D8E4F8;
    border-top: 3px solid;
    width: 100%;
}

.mailTxt {
    color: #100146 !important;
}

.checkbox input {
    border-radius: 50% !important;
}

.emailForm {
    background-color: var(--bg-secondary);
    transition: all 0.5s linear;
}

.waBtn {
    background: #fff !important;
    color: #2FBE39 !important;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 6px;
}

.socials_grid{
     display: flex;
                justify-content: center;
                background: var(--bg-secondary);
                /* margin: 0px 2rem  */
}

.actionBtnsm {
    background-color: var(--bg-secondary);
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 5px;
    gap: 5px;
    cursor: pointer;
}

.actionBtnsm:hover {
    background-color: var(--secondary-color);
    color: #fff;
}


/* @media screen and (max-width:575px) {
  
    .socials_grid{
    margin: 0px 0.5rem;
    }
} */