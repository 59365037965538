.container{
    display: flex;
    flex-wrap: nowrap;
}

/* @media screen and (max-width: 991) {
    .container{
       width: ;
    }
} */

@media screen and (max-width: 1240px   ) {
      .container{
        
        flex-wrap: wrap;
    }
    .RecommendedCards{
        justify-content: center;
        /* column-gap: 10px;
        row-gap: 10px; */
    }

}

@media screen and (max-width: 991px) {

    .container{
        
        flex-wrap: wrap;
    }
    .RecommendedCards{
        justify-content: center;
        column-gap: 10px;
        row-gap: 10px;
    }

    
}

@media screen and (max-width: 576px) {

    .container{
        flex-wrap: wrap;
    }
    .RecommendedCards{
        justify-content: center;
        column-gap: 10px;
        row-gap: 10px;
    }
   
    
}