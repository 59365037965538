.active_tab {
  color: #d957f9;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 2px solid;
}

.tabs {
  color: #39414a;
  font-size: 18px;
  font-weight: 700;
}

.tabs:hover{
  color: #d957f9;
}
.tabs:active{
  color: #d957f9;

}

@media screen  and (max-width: 768px) {
  .tabRow :global(.nav){
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    text-wrap: nowrap !important;
    overflow-y: hidden !important;
  }
  
}

@media screen and (max-width: 576px) {
  .tabRow :global(.nav){
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    text-wrap: nowrap !important;
    overflow-y: hidden !important;
  }
  
}





