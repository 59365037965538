.SignDiv {
  padding: 30px 80px;
}
.bannerText {
  font-size: 24px !important;
  color: #0d0c22;
  line-height: 29px;
}
.loginMethod {
  font-size: 22px !important;
  color: #100146;
  line-height: 29px;
}
.signup_content__line_break {
  position: relative;
  display: flex;
  margin: 30px 0;
  justify-content: center;
}
.divider {
  width: 100%;
  text-align: center;

  border-top: 1px solid #dbdbde;
}
.signup_content__or {
  position: absolute;
  top: -11px;
  left: 46%;
  padding: 0 8px;
  background: #fff;
  color: #6e6d7a;
  font-family: var(--font-face, "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.modal {
  padding: 0px !important;
}
.cross {
  position: absolute;
  z-index: 1;
  top: -10px;
  right: -10px;
  margin: 0;
  transition: color 200ms ease;
  color: #0d0c22;
  text-decoration: none;
}
