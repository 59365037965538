.swiper :global(.swiper-button-prev) {
  height: 60px !important;
  width: 60px !important;
  border-radius: 50% !important;
  background: #edf2fa !important;
  box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
  margin-left: 10px !important;
}
.swiper :global(.swiper-button-next) {
  height: 60px !important;
  width: 60px !important;
  border-radius: 50% !important;
  background: #edf2fa !important;
  box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
  margin-left: 10px !important;
}

.swiper :global(.swiper-button-prev)::after {
  font-size: 18px !important;
  color: #5932ea !important;
}
.swiper :global(.swiper-button-next)::after {
  font-size: 18px !important;
  color: #5932ea !important;
}

.swiper :global .listingdiv :global(.main) {
  margin: 0px 300px;
}
.results {
  font-size: 16px;
}

@media screen and (max-width: 1600px) {
  .listingdiv :global(.main) {
    margin: 0px 100px;
  }
}
@media screen and (max-width: 1300px) {
  .listingdiv :global(.main) {
    margin: 0px 10px !important;
  }
}
.products_listing_div {
  background-color: #edf2fa;
}
.searchbar img {
  left: 13px;
}

.searchbar input {
  padding-left: 2.5rem !important;
}
.dropdowns img {
  left: 15px;
}
.h1 {
  font-size: 24px;
}
.filter_btn {
  background: #edf2fa !important;
  color: #100146;
}

.filter_btn:global(.btn):active {
  color: #d957f9;
}
.filter_btn:global(.btn):hover {
  color: #d957f9;
}

@media screen and (max-width: 576px) {
  .h1 {
    font-size: 18px;
  }
  .results {
    font-size: 14px;
  }
  .listingdiv :global(.form-select) {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .h1 {
    font-size: 18px;
  }
  .results {
    font-size: 14px;
  }
  .listingdiv :global(.form-select) {
    font-size: 14px;
  }
}
