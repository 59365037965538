.h1 :global(.badge){
  align-self: start;
  font-size: 12px;
}


@media screen and (max-width: 1600px)
{
.specs_div :global(.main) {
    margin: 0px 100px;
}}
@media screen and (max-width: 1300px)
{
.specs_div :global(.main) {
    margin: 0px 10px !important;
}}
.filter_btn{
    background: #EDF2FA !important; 
    color:#100146
  }


  .filter_btn:global(.btn):active{
    color:#D957F9
  }
  .filter_btn:global(.btn):hover{
    color:#D957F9
  }.filter_btn{
    background: #EDF2FA !important; 
    color:#100146
  }


  .filter_btn:global(.btn):active{
    color:#D957F9
  }
  .filter_btn:global(.btn):hover{
    color:#D957F9
  }
  .h1{
    font-size: 24px;
}

@media screen and (max-width: 768px) {
  .h1{
    font-size: 18px;
}
}

@media screen and (max-width: 576px) {
  .h1{
    font-size: 18px;
}
}