
.title {
    /* height: 28%; */
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 40px;

  }
  .title p {
    white-space: normal;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis !important;
    font-size: 1rem;
    font-weight: 500;
  }
  .cardImg{
    object-fit: contain;
    height: 128px;
}
  .title_content:hover{
    color: #d957f9;
  }
  
.specsCard{
  border:  1px solid #D8E4F8;
  height: 100%;
  max-width: 200px;
   padding: 0.7rem; 
}

@media screen and ( max-width: 768px) {
  .title_content{
    font-size: 14px !important;
  }
  
}

@media screen and ( max-width: 576px) {
  .specsCard{
    width: 11rem;
  }
  .title_content{
    font-size: 14px !important;
  }
}
 
 

  