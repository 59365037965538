.thumbnailSliderWrap {
  height: 500px !important;
  overflow: hidden;
}

.thumbnailSliderWrap img {
  border-radius: 3px;
}

.thumbnailSliderWrap :global(.slick-current img) {
  border: 2px solid var(--active-color);
}

.mainImage {
  height: 586px;
}

.mainImage div {
  height: 100%;
}

.mainImage .slickSlide {
  max-width: 625px;
  height: 100%;
}

.mainImage .slick-slide {
  width: 100% !important;
}

.mainImage .slickSlideImage {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.mainImage :global(.slick-arrow) {
  height: auto;
  color: var(--text-color);
  background: var(--text-color);
  width: 36px;
  height: 46.29px;
  text-align: center;
  z-index: 1;
  opacity: 0.8;
}

.mainImage :global(.slick-prev:before) {
  content: "" !important;
  line-height: 1.4 !important;
}

.mainImage :global(.slick-next:before) {
  content: "" !important;
  line-height: 1.4 !important;
}

.mainImage :global(.slick-prev) {
  left:0px;
}

.mainImage :global(.slick-next) {
  right:33px;
}


@media screen and (max-width: 992px) {
  .mainImage :global(.slick-slide) {
    height: 586px;
    /* max-width: 625px; */
  }

  .mainImage {
    height: 352px;
  }
  .thumbnailSliderWrap {
    height: auto !important;
  }
}

@media screen and (max-width: 575px) {
  .mainImage {
    height: 323px;
  }
  .mainImage :global(.slick-slide) {
    height: 323px;
    padding-right: 0 !important;
    width: 100% !important;
    /* opacity: 1 !important; */
  }
  .mainImage .slickSlide {
    max-width: 100%;
    height: 100%;
  }
  .mainImage :global(.slick-next) {
    right:0px;
  }
  .mainImage :global(.slick-dots li button:before) {
    color: var(--text-color);
  }
  .mainImage :global(.slick-dots li.slick-active button:before) {
    color: var(--active-color);
  }
  .mainImage .slickSlideImage {
    max-width: 100%;
    /* width: 411px; */
    object-fit: revert;
    height: 100%;
  }
}




/*SPECS PAGE SLICK*/


.specsMainImage {
  height: 500px;
}


.specsMainImage div {
  height: 100%;
}

.specsMainImage .slickSlide {
  max-width: 625px;
  height: 100%;
}

.specsMainImage .slick-slide {
  width: 100% !important;
}

.specsMainImage .slickSlideImage {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.specsMainImage :global(.slick-arrow) {
  height: auto;
  color: var(--text-color);
  background: var(--text-color);
  width: 36px;
  height: 46.29px;
  text-align: center;
  z-index: 1;
  opacity: 0.8;
}

.specsMainImage :global(.slick-prev:before) {
  content: "" !important;
  line-height: 1.4 !important;
}

.specsMainImage :global(.slick-next:before) {
  content: "" !important;
  line-height: 1.4 !important;
}

.specsMainImage :global(.slick-prev) {
  left:0px;
}

.specsMainImage :global(.slick-next) {
  right:33px;
}


.specsThumbnailSliderWrap {
  height: 500px !important;
  overflow: hidden;
}

.specsThumbnailSliderWrap img {
  border-radius: 3px;
}
.specsThumbnailSliderWrap :global(.slick-slide) {
  padding-right: 0 !important;
  object-fit: contain;
}

.specsThumbnailSliderWrap :global(.slick-current img) {
  border: 2px solid var(--active-color);
}


@media screen and (max-width: 992px) {

  .specsThumbnailSliderWrap {
    height: auto !important;
  }
}


@media screen and (max-width: 575px) {
  .specsMainImage {
    height: 300px;
  }
  .specsMainImage :global(.slick-slide) {
    height: 300px;
    padding-right: 0 !important;
    width: 100% !important;
    /* opacity: 1 !important; */
  }
  .specsMainImage .slickSlide {
    max-width: 100%;
    height: 100%;
  }
  .specsMainImage :global(.slick-next) {
    right:0px;
  }
  .specsMainImage :global(.slick-dots li button:before) {
    color: var(--text-color);
  }
  .specsMainImage :global(.slick-dots li.slick-active button:before) {
    color: var(--active-color);
  }
  .specsMainImage .slickSlideImage {
    max-width: 100%;
    /* width: 411px; */
    object-fit: revert;
    height: 100%;
  }
}

/*END SPECS PAGE SLICK*/