/* .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding-top: 5rem;
} */

.backIcon {
  right: 0;
  top: 0;
}

.bannerWrapper {
  background-image: url(../../public/images/bannerBgxl.jpg) !important;
  background-size: contain;
  background-position: bottom;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.bannerOverlay {
  display: "grid";
  grid-template-rows: "6fr 2fr 4fr";
  max-height: "300px";
  height: "300px";
  gap: "8px";
}

.phonesBanner {
  height: 100% !important;
  width: 100% !important;
  border: none;
  /* object-fit: cover; */
  overflow: hidden;
}

.grid_container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 6fr 2fr 4fr;
  max-height: 324px;
  height: 324px;
  column-gap: 10px;
}
.grid_container div {
  border-radius: 12px !important;
}

/* First Column */
.grid_item {
  grid-row: 1 / span 2;
}
.mainBanner {
  height: 324px;
  overflow: hidden;
  z-index: 0;
}

.mainBanner img {
  height: 324px;
}

/* Second Column with two equal rows */
.grid_subcontainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
}

/* Third Column with varied heights */
.grid_subcontainer_alt {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 8px;
  overflow: hidden;
}

/* Items within sub-containers */

.skeletonBanner {
  background: linear-gradient(90deg, hsl(210, 15%, 88%), hsl(210, 15%, 95%), hsl(210, 15%, 88%));
  background-size: 200%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px;
  width: 800px;
  height: 328px; /* Matches the banner height */
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.subitem_tall {
  grid-area: 1/1/3/2;
}

.bannerInner {
  display: flex;
  align-items: center;
  height: 95%;
  width: 100%;
  padding: 0px 400px;
}

.bannerImg {
  /* object-fit: contain; */
  width: 100%;
}

.bannerText {
  font-size: 24px !important;
  font-weight: 700;
}

.searchbar img {
  left: 10px;
}

.searchbar input {
  padding-left: 2.5rem !important;
}

.searchbar input,
.ctaButton button,
.dropdowns select {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.dropdowns img {
  left: 10px;
}

.dropdowns select {
  padding-left: 2rem;
}

.dropdowns option {
  padding-left: 0px !important;
}

.bannerTextWrapper {
  padding-right: 8rem;
  padding-left: 2rem;
}

@media screen and (max-width: 1600px) {
  .bannerTextWrapper {
    padding-right: 1rem;
  }
  .bannerTextWrapper h1 {
    font-size: 24px !important;
  }

  .bannerWrapper {
    background-image: url(../../public/images/bannerBgLg.jpg);
    background-size: contain;
    background-position: bottom;
    /* height: 70vh; */
    background-repeat: no-repeat;
  }

  .bannerInner {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px 240px;
  }
}

/* @media screen and (min-width: 1700px) {
  .grid_subcontainer img {
    object-fit: fill;
  }
} */

@media screen and (max-width: 1300px) {
  .bannerWrapper {
    background-image: url(../../public/images/bannerBg1200.jpg);
    background-size: contain;
    background-position: bottom;
    /* height: 70vh; */
    background-repeat: no-repeat;
  }

  .bannerTextWrapper h1 {
    font-size: 24px !important;
  }

  .bannerInner {
    gap: 0 !important;
    padding: 0px 250px !important;
  }

 
}

@media screen and (max-width: 1250px) {
  .bannerInner {
    padding: 0px 150px !important;
  }
  .bannerTextWrapper h1 {
    font-size: 24px !important;
  }
  .mainBanner {
    height: 316px;
  }

  .mainBanner img {
    height: 316px;
  }
}

/* @media screen and (max-width: 1300px) and (max-height: 800px) {
    .bannerWrapper {
        background-image: url(../../public/images/bannerBgmd.jpg) !important;
        background-size: contain;
        background-position: bottom;
        height: 70vh !important;
        background-repeat: no-repeat;
    }

    .bannerTextWrapper h1 {
        font-size: 1.5rem !important;
    }

    .bannerInner {
        gap: 0 !important;
    }

    .bannerImg {
        width: 350px;
        height: 400px;
    }
} */

@media screen and (max-width: 1024px) {
  .backIcon {
    left: 0;
    top: 0;
  }

  .mainBanner {
    height: 224px;
  }

  .mainBanner img {
    height: 224px;
  }

  .grid_subcontainer {
    height: 224px;
  }

  .grid_subcontainer_alt {
    height: 224px;
  }

  .grid_container {
    height: 250px;
  }
}
@media screen and (max-width: 993px) {
  /* .bannerImg {
    display: none;
  } */

  .bannerBgRight {
    max-width: 275px;
    max-height: 446px !important;
    height: 100%;
  }

  .bannerTextWrapper {
    width: 70%;
    margin: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .bannerWrapper {
    background-image: url(../../public/images/bannerBgmd.jpg) !important;
    background-size: contain;
    background-position: bottom;
    /* height: 70vh !important; */
    background-repeat: no-repeat;
  }

  .bannerInner {
    gap: 0 !important;
    padding: 0px 100px !important;
    flex-direction: column-reverse !important;
  }
  .bannerText {
    /* font-weight: 700; */
    font-size: 38px !important;
    line-height: 120%;
    margin-top: 60px;
  }
  .searchbar input,
  .ctaButton button,
  .dropdowns select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .grid_container {
    display: grid;
    grid-template-rows: 1fr 1fr 2fr;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    max-height: 600px;
    height: 550px;
  }
  .mainBanner {
    grid-area: 1 / 1 / 3 / 4;
    height: 300px;
  }

  .mainBanner img {
    height: 300px;
  }
  .grid_subcontainer {
    grid-area: 3 / 1 / 3 / 2;
    height: 230px;
  }

  .grid_subcontainer img {
    height: 115px !important;
  }

  .grid_subcontainer_alt img {
    height: 115px !important;
  }
  .grid_subcontainer_alt {
    /* grid-template-rows: 150px 60px; */
    height: 230px;
    grid-area: 3 / 2 / 3 / 4;
  }
}

@media screen and (max-width: 575px) {
  /* .bannerImg {
    width: 100%;
    max-width: 200px;
    height: 200px;
  } */

  .grid_container {
    display: grid;
    grid-template-rows: 1fr 1fr 2fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
    max-height: 550px;
    column-gap: 0;
    height: 250px;
  }
  .mainBanner {
    grid-area: 1 / 1 / 1 / 4;
    height: 150px;
  }

  .mainBanner img {
    height: 150px !important;
  }

  .grid_subcontainer {
    grid-area: 2 / 1 / 4 / 3;
    height: 124px;
  }

  .grid_subcontainer img {
    height: 124px !important;
  }
  .grid_subcontainer_alt {
    /* grid-template-rows: 150px 60px; */
    grid-area: 3 / 1 / 4 / 4;
    height: 220px !important;
  }

  .grid_subcontainer_alt img {
    /* height: 104px !important; */
    border-radius: 12px;
  }

  .subitem_tall {
    height: 140px;
  }
  .subitem_tall img {
    height: 140px !important;
    width: 100%;
  }
  .subitem {
    height: 80px;
  }
  .subitem img {
    height: 80px !important;
  }

  .lastBanner {
    height: 84px !important;
  }
  .bannerTextWrapper {
    width: 100%;
    margin: auto;
    padding-right: 0px !important;
  }
  .bannerTextWrapper h1 {
    font-size: 20px !important;
    text-align: center !important ;
  }
  .bannerWrapper {
    background-image: url(../../public/images/bannerbgsm.jpg) !important;
    background-size: contain;
    background-position: bottom;
    /* height: 60vh !important; */
    background-repeat: no-repeat;
  }

  .bannerInner {
    /* width: 85%; */
    padding: 0px !important;
    max-width: 100%;
    margin: auto !important;
  }
  .bannerText {
    font-size: 30px !important;
    margin: 1rem 0.6rem;
  }
  .ctaButton,
  .searchbar {
    margin: 0 0.6rem;
  }
  .dropdownItems {
    margin: 1rem 0.6rem 0rem !important;
  }
  .dropdownItems .cities {
    padding-left: 0px !important;
  }
  .dropdownItems .condition {
    padding-right: 0px !important;
  }
  .dropdownItems {
    margin-top: 8px !important;
  }
}

@media screen and (max-width: 768px) {
  .bannerTextWrapper h1 {
    font-size: 20px !important;
    text-align: center !important ;
  }
}

/* @media screen and (min-width: 993px) and (max-height: 860px) {
    .bannerWrapper {
        background-image: url(../../public/images/bannerBgLg.jpg);
        background-size: contain;
        background-position: bottom;
        height: 70vh !important;
        background-repeat: no-repeat;
    }
} */
/* 
@media screen and (min-width: 576px) and (max-height: 700px) {
    .bannerWrapper {
        background-image: url(../../public/images/bannerBgLg.jpg) !important;
        background-size: contain;
        background-position: bottom;
        height: 100vh !important;
        background-repeat: no-repeat;
    }
} */

/* @media screen and (max-width: 575px) and (max-height: 700px) {
    .bannerWrapper {
        background-image: url(../../public/images/bannerbgsm.jpg) !important;
        background-size: contain;
        background-position: bottom;
        height: 100vh !important;
        background-repeat: no-repeat;
    }
} */
/* 
@media screen and (max-width: 992px) and (max-height: 600px) {
  
    .bannerWrapper {
        background-image: url(../../public/images/bannerbgsm.jpg) !important;
        background-size: contain;
        background-position: bottom;
        height: 200vh !important;
        background-repeat: no-repeat;
    }
} */
