.TopBrandsDiv {
  background-color: #f5f6fa;
  padding: 50px 0;
}

.TopBrandsDiv h1 {
  font-size: 24px;
}
.TopBrandsDiv :global(.nav-tabs) {
  display: flex;
  flex-wrap: nowrap;
  /* overflow-x: scroll ;
    overflow-y: hidden; */
  justify-content: center;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 20px;
  border: none;
  margin: 0px 0.5rem;

  /* color: #000; */
}

.TopBrandsDiv :global(.nav-item) button {
  color: black;
}
.TopBrandsDiv :global(.nav-item):first-child {
  margin-left: 0.5rem !important ;
}
.TopBrandsDiv :global(.nav-item):last-child {
  margin-right: 0.5rem !important;
}

.TopBrandsDiv :global(.nav-item .active) {
  background: var(--primary-gradient);
  border-radius: 30px !important;
  color: #fff !important;
  width: 100% !important;
  padding-bottom: 10px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 25px;
  margin-top: 40px;
}

.gridItems {
  padding: 15px 10px;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
}
.gridItems img {
  height: 64px;
  width: 64px;
}
.gridItems h5 {
  font-size: 14px;
}

/* Base Skeleton Loader Styles */
.skeleton {
  background: linear-gradient(90deg, hsl(210, 15%, 88%), hsl(210, 15%, 95%), hsl(210, 15%, 88%));
  background-size: 200%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

/* Image skeleton */
.skeleton_image {
  width: 65px;
  height: 64px;
  margin: 0 auto;
  border-radius: 8px;
}

/* Text skeleton */
.skeleton_text {
  width: 50%;
  height: 20px;
  margin: 12px auto 0;
}

/* Apply the shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

/* Additional styles for alignment */
.skeleton_grid_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    margin: 20px 0.5rem 0 0.5rem;
  }

  .gridItems {
    padding: 15px 10px;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
  }
}
@media screen and (max-width: 564px) {
  .TopBrandsDiv :global(.nav-tabs) {
    justify-content: flex-start !important;
    border-radius: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 20px 0.5rem 0 0.5rem;
  }

  .gridItems {
    padding: 15px 10px;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
  }
  .gridItems img {
    height: 48px;
    width: 48px;
  }
}
